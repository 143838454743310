<template>
  <div class="mc-container">
    <div class="cm-header">
      <img
        style="height: 60px; width: 50px"
        src="@/assets/logo.png"
        alt="logo"
      />
      <div class="cm-text-logo">Mobilecrosspay</div>
    </div>
    <div class="cm-content">
      <div class="cm-box">
        <p class="info">
          Mobilecrosspay, facilite le paiement mobile en Afrique. Payer et
          encaisser simplement à distance en toute sécurité.
          <img class="illustration" src="@/assets/sp.svg" alt="illustration" />
        </p>
      </div>
      <div class="cm-form">
        <div style="margin: auto; width: 220px">
          <ul class="steps is-horizontal">
            <li
              :class="
                key === active ? 'steps-segment is-active' : 'steps-segment'
              "
              v-for="(item, key) in step"
              :key="key"
            >
              <span class="steps-marker"></span>
            </li>
          </ul>
        </div>
        <div v-if="active === 1">
          <p class="cm-form-title">
            Créer un compte pour accéder à tous nos services.
          </p>
          <form action="" autocomplete="off">
            <div class="form-add">
              <div>
                <span class="d-inline-block cm-mb-8" for="email">Nom</span>
                <text-input
                  v-model="form.lastname"
                  :errors="errors.lastname"
                  type="text"
                  mainClass="control has-icons-left"
                >
                  <span class="icon is-small is-left">
                    <i class="fas fa-user"></i>
                  </span>
                </text-input>
              </div>

              <div>
                <span class="d-inline-block cm-mb-8" for="email">Prénom</span>
                <text-input
                  v-model="form.firstname"
                  :errors="errors.firstname"
                  type="text"
                  mainClass="control has-icons-left"
                >
                  <span class="icon is-small is-left">
                    <i class="fas fa-user"></i>
                  </span>
                </text-input>
              </div>
            </div>

            <span class="d-inline-block cm-mb-8 cm-mt-10" for="email"
              >Adresse mail</span
            >
            <text-input
              v-model="form.email"
              :errors="errors.email"
              type="email"
              mainClass="control has-icons-left"
            >
              <span class="icon is-small is-left">
                <i class="fas fa-envelope"></i>
              </span>
            </text-input>

            <div class="password_section">
              <div>
                <span class="d-inline-block cm-mb-8 cm-mt-10" for="email"
                  >Mot de passe</span
                >
                <text-input
                  required
                  autocomplete="off"
                  type="password"
                  mainClass="control has-icons-left"
                  v-model="form.password"
                  :errors="errors.password"
                >
                  <span class="icon is-small is-left">
                    <i class="fas fa-lock"></i>
                  </span>
                </text-input>
              </div>
              <div>
                <span class="d-inline-block cm-mb-8 cm-mt-10" for="email"
                  >Confirmer le mot de passe</span
                >
                <text-input
                  required
                  autocomplete="off"
                  type="password"
                  mainClass="control has-icons-left"
                  v-model="form.password_confirmation"
                  :errors="errors.password_confirmation"
                >
                  <span class="icon is-small is-left">
                    <i class="fas fa-lock"></i>
                  </span>
                </text-input>
              </div>
            </div>

            <p class="cm-mt-10">
              <button
                @click.prevent="registerCustomer"
                :disabled="loading"
                :class="
                  loading
                    ? 'button is-fullwidth is-primary is-loading'
                    : 'button is-fullwidth is-primary'
                "
              >
                Valider
              </button>
            </p>
            <div class="cm-info-bottom">
              <div>
                <router-link style="font-size: 15px" to="/signin"
                  >Se connecter</router-link
                >
              </div>
            </div>
          </form>
        </div>
        <div v-else>
          <div class="ig-svg">
            <img src="@/assets/verify.svg" alt="" />
            <p class="text-center">
              Nous avons envoyé un lien de vérification à votre adresse
            </p>
            <p class="text-desc">
              Veuillez cliquer sur le lien de vérification que nous avons envoyé
              à <b>{{ form.email }}</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from "../../components/TextInput.vue";
import { TYPES } from "@/store/types";
const DEFAULT_FORM = {
  email: "",
  firstname: "",
  lastname: "",
  password: "",
  password_confirmation: "",
};
export default {
  components: { TextInput },
  data: () => ({
    step: 3,
    active: 1,
    loading: false,
    form: {
      email: "",
      firstname: "",
      lastname: "",
      password: "",
      password_confirmation: "",
    },
    errors: [],
  }),

  methods: {
    registerCustomer() {
      this.loading = true;
      this.$store
        .dispatch(
          `${TYPES.modules.USER}/${TYPES.actions.REGISTER_CUSTOMER}`,
          this.form
        )
        .then((res) => {
          this.errors = [];
          if (res) {
            this.active = 2;
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.mc-container {
  background-color: #ecf7ff;
  width: 100%;
  height: 100vh;
  .cm-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    height: 70px;
    width: 100%;
    img {
      height: 50px;
    }
    .cm-text-logo {
      margin-left: 10px;
      font-size: 20px;
      font-weight: bold;
    }
  }
}
.cm-content {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: row;
  grid-gap: 20px;
  margin-left: 10%;
  margin-right: 10%;
}
.info {
  font-weight: bold;
  font-size: 18px;
}
.cm-form {
  background-color: #fff;
  min-height: 400px;
  max-height: 600px;
  border-radius: 5px;
  padding: 50px;
  .cm-form-title {
    text-align: center;
    color: #222f5a;
    font-weight: bold;
    font-size: 17px;
    line-height: 21px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.cm-info-bottom {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 1024px) {
  .illustration {
    display: none !important;
  }
  .info {
    font-weight: bold;
    font-size: 18px;
    text-align: center !important;
  }
  .cm-content {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    margin-left: 10%;
    margin-right: 10%;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (max-width: 636px) {
  .form-add {
    grid-template-columns: 1fr !important;
    grid-gap: 10px !important;
  }

  .password_section {
    grid-template-columns: 1fr !important;
    grid-gap: 10px;
  }
}
.form-add {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}
.password_section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}
.ig-svg {
  img {
    height: 100px;
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  align-content: center;
  justify-content: center;
  padding: 30px;
}
.text-center {
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
}
.text-desc {
  margin-top: 30px;
  text-align: center;
}
</style>